"use client";

import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";

const labelVariants = cva(" leading-none text-content-secondary peer-disabled:cursor-not-allowed peer-disabled:opacity-70", {
    variants: {
        variant: {
            floating: "label--floating",
            default: "mb-2"
        },
        size: {
            default: "text-base",
            xs: "text-xs",
            sm: "text-sm"
        },
        bgColor: {
            default: "bg-background-primary",
            transparent: "bg-background-transparent"
        }
    },
    defaultVariants: {
        variant: "default",
        size: "default",
        bgColor: "default"
    }
});

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>>(
    ({ className, variant, size, bgColor, ...props }, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ size, variant, bgColor }), className)} {...props} />
);
Label.displayName = LabelPrimitive.Root.displayName;

export { Label, labelVariants };
