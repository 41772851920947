import * as React from "react";
import { Input, InputProps } from "./Input";
import { Label } from "./label";
import { RefCallback, RefObject } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";
import { ErrorMessage } from "../FormField";

const inputLabelVariants = cva("relative flex", {
    variants: {
        variant: {
            default: "flex-col gap-2",
            floating: "items-center rounded-md border border-none bg-background-primary shadow-faux-border"
        }
    },
    defaultVariants: {
        variant: "default"
    }
});

interface InputWithLabelProps extends VariantProps<typeof inputLabelVariants> {
    /** Used to connect the `<label>` to the `<input>`*/
    id?: string;
    /** Label text */
    label: string;
    /** Pass `HTMLInputElement` properties to the `<input>` */
    inputProps?: InputProps;
    inputRef?: RefObject<HTMLInputElement> | RefCallback<HTMLInputElement>;

    /** Pass Icon/SVG to render before the `<input>`. */
    startDecorator?: React.ReactNode;

    /** Wrapper class. */
    wrapperClass?: string;

    error?: ErrorMessage;
}

/**
 * Text Input with floating label on focus.
 */

const InputWithLabel = (props: InputWithLabelProps) => {
    const { id, label, wrapperClass, inputProps, inputRef, startDecorator, variant, error } = props;
    const inputId = !id ? label?.split(" ").join("-").toLowerCase() : id;
    const inputHasValue = String(inputProps?.value ?? "").length > 0;
    const inputClass = `input-value-${inputHasValue}`;
    const isDefaultVariant = variant === "default" || variant === undefined;
    // Default placeholder to a space to ensure peer-placeholder-shown pseudo-class works as expected (specifically for Safari)
    const placeholder = inputProps?.placeholder ?? " ";

    const wrapperClassConditions = {
        "shadow-faux-border-danger": !!error
    };

    const labelClassConditions = {
        "left-10": startDecorator && !inputHasValue,
        "order-first text-sm": isDefaultVariant
    };

    const inputClassConditions = {
        "shadow-faux-border": isDefaultVariant,
        "pl-12": isDefaultVariant && !!startDecorator
    };

    const decoratorClassConditions = {
        "absolute bottom-3 left-3 pl-0 z-50": isDefaultVariant
    };

    return (
        <div className={cn(inputLabelVariants({ variant }), wrapperClassConditions, wrapperClass)}>
            {startDecorator && <span className={cn("flex pl-3", decoratorClassConditions)}>{startDecorator}</span>}
            <Input id={inputId} variant={variant} className={cn(inputClass, inputClassConditions)} ref={inputRef} placeholder={placeholder} {...inputProps} />
            <Label htmlFor={inputId} variant={variant} className={cn("mb-0", labelClassConditions)}>
                {label}
            </Label>
        </div>
    );
};
InputWithLabel.displayName = "InputWithLabel";

export { InputWithLabel };
