import { z, ZodTypeAny } from "zod";
import { petQuoteErrorSchema } from "spot-types/errors/PetQuoteErrors";

const errorIdTypeSchema = z.union([petQuoteErrorSchema, z.literal("invalid-phone-number")]);

const spotErrorMessageSchema = z.object({
    id: errorIdTypeSchema,
    at: z.string().optional(),
    message: z.string().optional()
});

export const spotErrorResponseSchema = z.object({
    error: z.object({
        code: z.number(),
        message: z.array(spotErrorMessageSchema),
        id: z.string()
    })
});

const extractLiterals = (schema: ZodTypeAny): string[] => {
    const literals: string[] = [];
    const traverse = (schema: ZodTypeAny, depth: number) => {
        if (schema._def.typeName === "ZodUnion") {
            for (const option of schema._def.options) {
                traverse(option, depth + 1);
            }
        } else if (schema._def.typeName === "ZodLiteral") {
            literals.push(schema._def.value);
        } else {
            console.log(schema._def.typeName);
        }
    };
    traverse(schema, 0);

    return literals;
};

export const allSpotErrorIds = extractLiterals(errorIdTypeSchema as ZodTypeAny);

// types
export type SpotErrorResponse = z.infer<typeof spotErrorResponseSchema>;
export type ErrorIdType = z.infer<typeof errorIdTypeSchema>;
export type SpotErrorMessage = z.infer<typeof spotErrorMessageSchema>;
