import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";

const inputVariants = cva(
    "h-11 w-full appearance-none rounded-md bg-background-primary px-4 text-base text-content-primary placeholder:text-content-secondary focus:outline-none",
    {
        variants: {
            variant: {
                default: "",
                floating: "peer placeholder:opacity-0 focus:placeholder:opacity-100"
            }
        },
        defaultVariants: {
            variant: "default"
        }
    }
);

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, variant, type, placeholder = "", ...props }, ref) => {
    return <input type={type} className={cn(inputVariants({ variant }), className)} placeholder={placeholder} ref={ref} {...props} />;
});

Input.displayName = "Input";

export { Input };
